body::-webkit-scrollbar {
	display: none !important;
}

body {
	-ms-overflow-style: none !important; /* IE and Edge */
	scrollbar-width: none !important; /* Firefox */
}

.no-scrollbar {
	-ms-overflow-style: none !important; /* IE and Edge */
	scrollbar-width: none !important; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
	display: none !important;
}
